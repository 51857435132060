import React from 'react';
import Layout from '../../components/layout';
import Image from '../../atoms/image';
import SEO from '../../components/seo';
import { titles, descriptions } from '../../const/metaData';
import {
  BoldText,
  Wrapper,
  TextContainer,
  ImageContainer,
  ImageContent,
} from '../../styles/commonStyles';

const Cookies: React.FunctionComponent = () => {
  return (
    <Layout>
      <SEO title={titles.cookies} description={descriptions.cookies} />
      <Wrapper>
        <TextContainer>
          <h2>Cookies Policy</h2>
          <BoldText marginTop>Introduction</BoldText>
          <p>
            This cookie notice is for visitors to our website and people who use
            our app. It sets out how we use cookies. On your first visit to our
            homepage, a banner appeared to make it clear that this website used
            cookies, provided you with options to manage your cookie preferences
            and directed you towards this Cookie Policy.
          </p>
          <BoldText>Cookies - what are they?</BoldText>
          <p>
            A "cookie" is a small text file that's stored on your computer,
            smartphone, tablet, or other device when you visit a website or use
            an app. Some cookies are deleted when you close your browser. There
            are known as session cookies. Others remain on your device until
            they expire, or you delete them from your cache. These are known as
            persistent cookies and enable us to remember things about you as a
            returning visitor. This website uses session and persistent cookies.
          </p>
          <BoldText>Changing your cookie preferences</BoldText>
          <p>
            If you want to restrict or block the cookies we set, or find out
            more about what cookies we use, you can do this through Cookie
            Settings. Alternatively, you can search the internet for other
            independent information on cookies. If you choose to block all
            cookies, you may experience problems accessing certain areas of this
            website.
          </p>
          <BoldText>Cookies - how we use them</BoldText>
          <p>
            If you delete cookies relating to this website, we will not remember
            things about you, including your cookie preferences, and you will be
            treated as a first-time visitor the next time you visit the site. We
            use cookies (and other similar technologies) to manage our marketing
            relationships (tracking number of visitors, geography and
            acquisition source) and to give you a better online experience and
            track website performance. These cookies collect aggregated
            information and are not used to identify you.
          </p>
          <BoldText>Cookies in emails</BoldText>
          <p>
            As well as the cookies we use on our website, we use cookies and
            similar technologies in emails. These help us to understand whether
            you’ve opened an email, how you’ve interacted with it and your
            geographic location. Cookies may also be set if you click on a link
            within the email.
          </p>
          <BoldText>How to control and delete cookies</BoldText>
          <p>
            If you don't want to accept cookies in emails, you can set your
            browser to restrict or reject cookies, or you can close the email
            before downloading any images or clicking on any links.
          </p>
        </TextContainer>
        <ImageContainer>
          <ImageContent>
            <Image imagePath="letter" />
          </ImageContent>
        </ImageContainer>
      </Wrapper>
    </Layout>
  );
};

export default Cookies;
